<template>
  <div class="app-container summary-page product-manage">
    <eHeader
      ref="eheader"
      @toQuery="toQueryHandle"
    ></eHeader>
    <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="login" label="商品图片">
        <template slot-scope="scope">
          <span>
            <img
                width="40px"
                height="40px"
                :src="scope.row.frontImg"
                alt=""
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="商品名称" />
      <el-table-column label="语言">
        <template slot-scope="scope">
          <span>{{getLang(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ruleName" label="商品类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="originalPrice" label="原价"/>
      <el-table-column prop="creditsExchange" label="优惠价">
        <template slot-scope="scope">
          <span>{{scope.row.preferentialPrice || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cmd" label="标签">
        <template slot-scope="scope">
          <span>{{scope.row.tagName || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="realSales" label="销量" />
      <el-table-column prop="updateTime" label="更新时间" />
      <el-table-column prop="openPrice" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          width="150px"
          prop="openPrice"
          label="操作">
        <template slot-scope="scope">
          <el-button
              v-if="checkPermission(['ADMIN','TCustRewardRule_ALL','TCustRewardRule_EDIT'])"
              size="mini"
              @click="editHandle(scope.row)"
              type="success"
          >编辑</el-button>
<!--          <el-popover
              v-if="checkPermission(['ADMIN','TCustRewardRule_ALL','TCustRewardRule_DELETE'])"
              :ref="scope.row.id"
              placement="top"
              width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="deleteHandle(scope.row.id)">确定</el-button>
            </div>
            <el-button class="my-button" slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>-->
        </template>
      </el-table-column>
    </el-table>

    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteRule } from '@/api/intergratingManage'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/intergratingManage/productManage/eheader'
export default {
  name:'productManage',
  components: {
    eHeader,
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      delLoading: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  watch:{
    isRefresh(val){
      if(val){
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters(['isRefresh']),
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      let header = this.$refs.eheader;
      this.url = 'crm/tShopProduct';
      this.params = {
        page: this.page,
        size: this.size,
      }
      if(header.lang){
        this.params.lang = header.lang;
      }
      if(header.type){
        this.params.type = header.type;
      }
      if(header.status !== ''){
        this.params.status = header.status;
      }
      if(header.productName){
        this.params.productName = header.productName;
      }
      if(header.startTime){
        this.params.startTime = parseTime(header.startTime)
      }
      if(header.endTime){
        this.params.endTime = parseTime(header.endTime)
      }
      return true;
    },
    getLang(row){
      switch (row.lang){
        case 'zh_CN':
          return '中文';
        case 'zh_Hant':
          return '繁体';
        case 'en_US':
          return '英文';
      }
    },
    getType(row){
      if(row.type == 1){
        return '实物';
      }else if(row.type == 2){
        return '虚拟';
      }
    },
    getActionType(row){
      if(row.actionType == 1){
        return '首次入金';
      }else if(row.actionType == 2){
        return '单次入金';
      }else {
        return '-';
      }
    },
    getStatus(row){
      switch (row.status){
        case 1:
          return '已上架';
        case 0:
          return '已下架';
      }
    },
    deleteHandle(id){
      this.delLoading = true;
      deleteRule({
        id: id,
      }).then((res) => {
        this.$refs[id].doClose();
        this.delLoading = false;
        this.init();
        this.$notify({
          message: '删除成功',
          duration: 2000,
        })
      }).catch(err => {
        this.delLoading = false;
        this.$refs[id].doClose();
      })
    },
    editHandle(row){
      //todo
      this.$router.push({name: 'editProduct', params:{id: row.id}});
    },
    toQueryHandle(){
      this.init();
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    success(){
      this.editShow = false;
      this.page = 0;
      this.init();
    },
  }
}
</script>

<style lang="less">
  .product-manage {
    .my-button {
      margin-left: 5px;
    }
  }
</style>
