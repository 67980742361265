<template>
<div class="head-container">
  <el-select
      v-model="lang"
      @change="toQuery"
      clearable
      placeholder="语言"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in langOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-model="type"
      @change="toQuery"
      clearable
      placeholder="商品类型"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in typeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-select
      v-model="status"
      @change="toQuery"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
  >
    <el-option
        v-for="item in statusOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    />
  </el-select>
  <el-input
      v-model="productName"
      clearable
      placeholder="商品名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
  />
  <div class="filter-item">
    创建时间
    <el-date-picker
        style="width: 150px;z-index:99;"
        v-model="startTime"
        type="date"
        placeholder="开始时间">
    </el-date-picker>
    至
    <el-date-picker
        style="width: 150px;"
        v-model="endTime"
        type="date"
        placeholder="结束时间">
    </el-date-picker>
  </div>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-search"
      @click="toQuery"
  >搜索</el-button>
  <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-plus"
      @click="toAdd"
  >新增</el-button>
</div>
</template>

<script>
export default {
  name: 'eheader',
  data() {
    return {
      lang: '',
      langOptions: [
        { label: '中文', value: 'zh_CN' },
        { label: '繁体', value: 'zh_Hant' },
        { label: '英文', value: 'en_US' },
      ],
      type: '',
      typeOptions: [
        { label: '实物', value: 1 },
        { label: '虚拟', value: 2 },
      ],
      status: '',
      statusOptions:[
        { label: '已上架', value: 1 },
        { label: '已下架', value: 0 },
      ],
      productName: '',
      startTime: '',
      endTime: '',
    }
  },
  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
       this.type = '';
       this.status = '';
       this.productName = '';
       this.startTime = '';
       this.endTime = '';
    },
    toAdd(){
      // 考虑是否将添加和编辑页面分开
      this.$router.push({name: "addProduct"});
    }
  }
}
</script>

<style scoped>

</style>
